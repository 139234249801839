<template>
    <v-menu bottom offset-x rounded="lg" transition="slide-y-transition" color="primary">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="teal darken-1" class="white--text ma-1">
                <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item @click="printCashRegister(item)">
                <v-list-item-title>
                    <v-icon x-small class="mr-2">fas fa-print</v-icon> Imprimir caja
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
        title-pdf="CAJA" :handlePdfModal="handlePdfModal"
        :dialog="showPdf" v-if="showPdf" />
    </v-menu>
</template>
<script>
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';
import PreviewPDF from '@/components/shared/PreviewPDF.vue';

export default {
    name: "ActionsCashRegister",
    props:{
        item: { type: Object }
    },
    components: {
        PreviewPDF
    },
    data() {
        return {
            pdfBlob: '',
            pdfSource: null,
            showPdf: false,
            lstSale: []
        }
    },
    methods: {
        printCashRegister(item){
            CashRegisterDataService.printCashRegister(item.id).then( response => {
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdf = true
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            });
        },
        handlePdfModal(){
            this.showPdf = !this.showPdf
            this.cleanupPdf()
        },
        cleanupPdf(){
            if(this.pdfSource){
                URL.revokeObjectURL(this.pdfSource)
                this.pdfSource = null
            }
            this.pdfBlob = null
        }
    }
}
</script>